<script>
import { Link } from "@inertiajs/inertia-vue3";

export default {
    components: {
        Link
    },
    props: {
        activeSubMenu: {
            type: Boolean,
            default: true
        },
        menuItems: Object,
        auth: Object
    },
    emits: ["menuShown2"],
    methods: {
        menuShown(val) {
            console.log("profile menu", val);
            this.active = val;
            this.$emit("menuShown2", val);
        },
        backGround() {
            this.menuShown(false);
        },
        MenuEvent() {
        }
    }
};
</script>

<template>
    <!--    <main class="w-screen bg-[#0000007a] absolute top-[0] left-[0] z-60 h-screen ts:hidden" @click="backGround()" v-if="active" @mouseenter="menuShown(true)" @mouseleave="menuShown(false)"></main>-->
    <header
        class="absolute top-[-60px] left-[-35px] bg-white max-w-67 px-5 pt-2 pb-5 z-80 min-w-[300px] ts:hidden border-2 border-l-0 border-t-0 border-bordergray"
        @click="MenuEvent()" v-if="activeSubMenu" @mouseenter="menuShown(true)" @mouseleave="menuShown(false)">
        <!--        <div class="mr-4 mb-4">-->
        <!--            <h1>-->
        <!--                <Link :href="`/`"><img class="w-[220px] mx-auto" src="../../images/logo.png" alt="" /></Link>-->
        <!--            </h1>-->
        <!--        </div>-->
        <div class="flex items-center pb-1 border-b-2 border-bordergray mb-4">
            <ul class="flex w-full justify-around mb-2">
                <li class="text-base md:text-base">
                    <Link href="/istek-listem" class="flex justify-center items-center">
                        <div class="w-14 h-14 border-2 border-black rounded-full flex justify-center items-center">
                            <div class="text-[23px] text-kbgreen leading-none font-bold">
                                {{
                                    auth.user?.full_name
                                        .split(" ")
                                        .map((n) => n[0])
                                        .join("")
                                }}
                            </div>
                        </div>
                        <div class="text-base font-kiralabunuthin ml-2">{{ auth.user?.full_name }}</div>
                    </Link>
                </li>
            </ul>
        </div>
        <div class="flex items-center pb-1 border-b-2 border-bordergray mb-4">
            <ul class="flex w-full mb-2">
                <li class="text-base md:text-base">
                    <div class="flex items-center" @click="menuShown(false)">
                        <div
                            class="w-[46px] h-[46px] border-2 border-bordergray rounded-full flex justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.926" height="20.737"
                                 viewBox="0 0 11.926 20.737">
                                <path id="Path_18" data-name="Path 18"
                                      d="M86.422,320.457a1.558,1.558,0,0,1,2.2,0l7.71,7.71,7.71-7.71a1.557,1.557,0,1,1,2.2,2.2l-8.811,8.811a1.558,1.558,0,0,1-2.2,0l-8.811-8.811A1.558,1.558,0,0,1,86.422,320.457Z"
                                      transform="translate(331.927 -85.966) rotate(90)" fill="#231f20" />
                            </svg>
                        </div>
                        <div class="text-base font-kiralabunuthin ml-2">Geri</div>
                    </div>
                </li>
            </ul>
        </div>
        <nav class="mt-3 pb-1 border-b-2 border-bordergray">
            <ul class="flex flex-col font-bold text-base">
                <li class="flex justify-between items-start">
                    <div class="cursor-pointer">
                        <Link href="/profili-duzenle" class="">
                            <div class="relative py-1 my-1">
                                <span class="whitespace-nowrap text-base">Profili Düzenle</span>
                                <!--                                <svg class="absolute top-1.5 -left-1" width="9" height="9" viewBox="0 0 9 9">-->
                                <!--                                    <circle id="Ellipse_370" data-name="Ellipse 370" cx="4.5" cy="4.5" r="4.5" fill="#5f4af4" />-->
                                <!--                                </svg>-->
                            </div>
                        </Link>
                    </div>
                    <!--                    <Link class="mt-1" href="/profil-duzenle" >-->
                    <!--                        <svg xmlns="http://www.w3.org/2000/svg" width="8.81" height="15.318" viewBox="0 0 8.81 15.318">-->
                    <!--                            <path id="Path_55" data-name="Path 55" d="M.337.337a1.151,1.151,0,0,1,1.627,0l5.7,5.7,5.7-5.7a1.15,1.15,0,1,1,1.627,1.627L8.473,8.473a1.151,1.151,0,0,1-1.627,0L.337,1.964A1.151,1.151,0,0,1,.337.337Z" transform="translate(0 15.318) rotate(-90)" fill="#231f20" />-->
                    <!--                        </svg>-->
                    <!--                    </Link>-->
                </li>
                <ul class="ml-6">
                    <li>
                        <Link href="/profili-duzenle">
                            <div class="py-1 my-1">
                                <span class="whitespace-nowrap text-base font-semibold">Hesap Bilgileri</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link href="/sifremi-yenile">
                            <div class="py-1 my-1">
                                <span class="whitespace-nowrap text-base font-medium">Şifre Değişikliği</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link href="/iletisim-bilgileri">
                            <div class="py-1 my-1">
                                <span class="whitespace-nowrap text-base font-semibold">İletişim Bilgileri</span>
                            </div>
                        </Link>
                    </li>
                </ul>

                <li class="flex justify-between items-center cursor-pointer">
                    <Link @click="menuShown(false)" href="/kiralamalar">
                        <div class="py-1 my-1">
                            <span class="whitespace-nowrap text-base">Kiralamalar</span>
                        </div>
                    </Link>
                </li>
                <li class="flex justify-between items-start">
                    <div class="cursor-pointer">
                        <Link href="/destek-taleplerim">
                            <div class="py-1 my-1">
                                <span class="whitespace-nowrap text-base">Destek Talepleri</span>
                            </div>
                        </Link>
                    </div>
                    <!--                    <Link class="mt-1" href="/profil-duzenle" >-->
                    <!--                        <svg xmlns="http://www.w3.org/2000/svg" width="8.81" height="15.318" viewBox="0 0 8.81 15.318">-->
                    <!--                            <path id="Path_55" data-name="Path 55" d="M.337.337a1.151,1.151,0,0,1,1.627,0l5.7,5.7,5.7-5.7a1.15,1.15,0,1,1,1.627,1.627L8.473,8.473a1.151,1.151,0,0,1-1.627,0L.337,1.964A1.151,1.151,0,0,1,.337.337Z" transform="translate(0 15.318) rotate(-90)" fill="#231f20" />-->
                    <!--                        </svg>-->
                    <!--                    </Link>-->
                </li>
                <ul class="ml-4">
                    <li>
                        <Link href="/gecmis-taleplerim">
                            <div class="py-1 my-1">
                                <span class="whitespace-nowrap text-base font-semibold">Geçmiş Taleplerim</span>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link href="/talep-olustur">
                            <div class="py-1 my-1">
                                <span class="whitespace-nowrap text-base font-medium">Talep Oluştur</span>
                            </div>
                        </Link>
                    </li>
                </ul>
                <li class="flex justify-between items-center cursor-pointer">
                    <Link href="/adreslerim">
                        <div class="py-1 my-1">
                            <span class="whitespace-nowrap text-base">Adresler</span>
                        </div>
                    </Link>
                </li>
                <li class="flex justify-between items-center cursor-pointer">
                    <Link href="/istek-listem-tavsiye">
                        <div class="py-1 my-1">
                            <span class="whitespace-nowrap text-base">Tavsiye Davetleri</span>
                        </div>
                    </Link>
                </li>
                <li class="flex justify-between items-center cursor-pointer">
                    <Link href="/istek-listem">
                        <div class="py-1 my-1">
                            <span class="whitespace-nowrap text-base">İstek Listem</span>
                        </div>
                    </Link>
                </li>
                <!--                <li class="flex justify-between items-center cursor-pointer">-->
                <!--                    <Link href="/kuponlarim">-->
                <!--                        <div class="py-1 my-1">-->
                <!--                            <span class="whitespace-nowrap text-base">Kuponlar</span>-->
                <!--                        </div>-->
                <!--                    </Link>-->
                <!--                </li>-->
                <li class="flex justify-between items-center cursor-pointer">
                    <Link href="/odeme-yontemlerim">
                        <div class="py-1 my-1">
                            <span class="whitespace-nowrap text-base">Ödeme Yöntemleri</span>
                        </div>
                    </Link>
                </li>
            </ul>
        </nav>
        <div class="mt-3 pb-1 border-b-2 border-bordergray">
            <ul class="flex flex-col text-xl leading-6.5 font-medium">

                <li class="p-0 m-0 mb-2 text-base text-left text-gray-900 box-border whitespace-no-wrap">
                    <Link href="/nasil-calisir">Nasıl Çalışır?</Link>
                </li>
                <li class="p-0 m-0 mb-2 text-base text-left text-gray-900 box-border whitespace-no-wrap">
                    <Link href="/kurumsal">Kurumsal</Link>
                </li>
                <li class="p-0 m-0 mb-2 text-base text-left text-gray-900 box-border whitespace-no-wrap"><a href="">İndirimli
                    Ürünler</a></li>
                <li class="p-0 m-0 mb-2 text-base text-left text-gray-900 box-border whitespace-no-wrap">
                    <a href="https://kiralamini.com/" target="_blank">
                        <img class="w-full max-w-[120px]"
                             src="https://kiralamini.com/wp-content/uploads/2023/02/cropped-logo-2048x344.png" alt="">
                    </a>
                </li>
            </ul>
        </div>
        <div class="flex items-center mt-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="22.401" height="25.546" viewBox="0 0 22.401 25.546">
                <g id="noun-exit-119208" transform="translate(-128.547 -26.727)">
                    <path id="Path_3738" data-name="Path 3738"
                          d="M130.729,212.969H141.32a.642.642,0,0,0,0-1.284H130.729l1.861-1.861a.635.635,0,1,0-.9-.9l-2.953,2.953c-.064.064-.128.128-.128.192a.545.545,0,0,0,0,.513c.064.064.064.128.128.192l2.953,2.953a.621.621,0,0,0,.9,0,.62.62,0,0,0,0-.9Z"
                          transform="translate(0 -172.827)" fill="#231f20" />
                    <path
                        id="Path_3739"
                        data-name="Path 3739"
                        d="M263.757,26.727h-9.885a3.15,3.15,0,0,0-3.145,3.145v5.841a.642.642,0,0,0,1.284,0V29.872a1.863,1.863,0,0,1,1.861-1.861h9.949a1.863,1.863,0,0,1,1.861,1.861V49.128a1.863,1.863,0,0,1-1.861,1.861h-9.949a1.863,1.863,0,0,1-1.861-1.861v-5.52a.642.642,0,0,0-1.284,0v5.52a3.15,3.15,0,0,0,3.145,3.145h9.949a3.15,3.15,0,0,0,3.145-3.145V29.872a3.165,3.165,0,0,0-3.209-3.145Z"
                        transform="translate(-116.018 0)"
                        fill="#231f20"
                    />
                </g>
            </svg>

            <div class="ml-3 whitespace-nowrap text-base font-bold">
                <Link href="/cikis-yap">Çıkış Yap</Link>
            </div>
        </div>
    </header>
</template>
